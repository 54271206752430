.online {
  display: flex;
  justify-content: space-between;
  width: 850px;
  margin: 150px auto 20px;
}

@media (max-width: 800px) and (min-width: 700px) {
  .online {
    width: 700px;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .online {
    display: block;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .online {
    width: 350px;
    margin: 150px auto 20px;
  }
}

.online > img {
  width: 400px;
  height: 300px;
  object-fit: cover;
}

@media (max-width: 800px) and (min-width: 700px) {
  .online > img {
    width: 350px;
    height: 300px;
    object-fit: cover;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .online > img {
    width: 350px;
    height: 300px;
    object-fit: cover;
  }
}

.online_title {
  width: 400px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .online_title {
    width: 350px;
  }
}

.online_title_left {
  display: flex;
  margin: 20px 0 0 30px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .online_title_left {
    display: flex;
    margin: 20px 0 0;
  }
}

.online_title_left > h4 {
  font-size: 18px;
  line-height: 35px;
  margin-left: 15px;
}

.online_title > h5 {
  font-size: 15px;
  margin: 20px 0 0 30px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .online_title > h5 {
    margin: 20px 0 0;
  }
}

.online_title > p {
  font-size: 13px;
  color: #373737;
  line-height: 25px;
  margin: 20px 0 0 30px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .online_title > p {
    margin: 20px 0 0 0px;
  }
}

.online_title_box {
  width: 3px;
  height: 35px;
  background-color: #222222;
}

.online_title_btn {
  width: 300px;
  height: 50px;
  background-color: #855B33;
  color: white;
  line-height: 50px;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #222222;
  font-weight: 600;
  margin: 20px 0 0 30px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .online_title_btn {
    width: 100%;
    margin: 20px 0 0 0px;
  }
}

.online_title_btn > div {
  position: relative;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  margin: 12.5px 0;
  margin-right: 15px;
}

.online_title_btn_icons {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  margin: 3.5px;
  color: #855B33;
}

.online_title_text {
  margin-left: 30px;
}


