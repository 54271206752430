@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif JP', serif;;
}

.nav {
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  background-color: #A89A8E;
}

@media (max-width: 1300px) and (min-width: 0px) {
  .nav {
    width: 90vw;
    padding: 0 5vw;
  }
}

.nav > img {
  height: 40px;
  margin: 20px 20px 20px 80px;
}

@media (max-width: 1300px) and (min-width: 0px) {
  .nav > img {
    margin: 20px 20px 20px 0;
  }
}

.nav > div {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin-right: 80px;
}

@media (max-width: 1300px) and (min-width: 700px) {
  .nav > div {
    margin-right: 0px;
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .nav > div {
    width: 130px;
    margin-right: 0px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .nav > div {
    width: 130px;
    margin-right: 0px;
  }
}

.nav > div > a > p {
  font-size: 15px;
  line-height: 40px;
  margin: 20px 0;
  color: white;
  font-weight: bold;
}

.nav > div > p {
  font-size: 15px;
  line-height: 40px;
  margin: 20px 0;
  color: white;
  font-weight: bold;
}

@media (max-width: 700px) and (min-width: 0px) {
  .nav_mini {
    display: none;
  }
}

.nav_onlineshop {
  width: 130px;
  background-color: #855B33;
  color: white;
  text-align: center;
}
