.menu_title {
  text-align: center;
  margin: 150px 0 50px;
  color: #222222;
}

.menu_title > h4 {
  font-size: 25px;
}

.menu_title > h5 {
  font-size: 15px;
  margin-top: 10px;
}

.menu {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 80vw;
  margin: 0 auto;
}

.menu > div {
  width: 300px;
  margin: 50px 30px 0;
}

.menu > div > img {
  width: 300px;
  height: 180px;
  object-fit: cover;
}

.menu > div > p {
  width: 300px;
  font-size: 14px;
  color: #222222;
  margin-top: 10px;
  line-height: 25px;
}