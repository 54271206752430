body * {
  color: #222222;
}

.react-calendar {
  width: 450px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin: 0 auto;
}
 
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
 
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
 
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  border-radius: 6px;
}
 
.react-calendar__tile--now {
  border-radius: 6px;
  font-weight: bold;
  color: #5C5966;
}
 
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
  font-weight: bold;
}
 
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
 
.react-calendar__tile--active {
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
 
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  color: white;
}
 
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
 
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #5C5966;
  border-radius: 0;
}
 
.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: #5C5966;
  color: white;
}
 
.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: #5C5966;
  color: white;
}

.react-calendar .react-calendar__tile:nth-child(7n) {
  color: #00F;
}

