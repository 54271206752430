.header {
  width: 100vw;
  display: flex;
  padding-top: 80px;
  height: 515px;
}

.header_left {
  margin: 80px 0 20px;
  width: 550px;
  text-align: center;
}

.header_left > img {
  height: 250px;
}

.header_left > p {
  font-size: 13px;
  color: #222222;
  font-weight: 500;
  margin: 30px 0 0;
  line-height: 25px;
}

.header_right {
  width: calc(100vw - 550px);
}

@media (max-width: 1000px) and (min-width: 0) {
  .header {
    display: none;
  }
}

@media (max-width: 10000px) and (min-width: 1000px) {
  .header_mini {
    display: none;
  }
}

@media (max-width: 1000px) and (min-width: 0px) {
  .header_mini {
    position: relative;
    padding-top: 80px;
    height: 500px;
  }

  .header_slider_mini {
    position: absolute;
    width: 100vw;
    height: 500px;
    object-fit: cover;
  }

  .header_box {
    position: absolute;
    width: 100vw;
    height: 500px;
  }

  .header_box_background {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 450px;
    background-color: white;
    opacity: .8;
    z-index: 1;
    margin: 25px 0;
  }

  .header_box_items {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .header_box_items > img {
    height: 230px;
    width: 330px;
    z-index: 2;
    margin: 100px calc((100vw - 330px) / 2) 0px;
    object-fit: contain;
  }

  .header_box_items > p {
    width: 350px;
    z-index: 2;
    margin: 0px calc((100vw - 350px) / 2);
    text-align: center;
    font-weight: bold;
  }
}


