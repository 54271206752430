.f2_company_title {
  position: relative;
  top: -20px;
}

.f2_company_title > h4 {
  position: absolute;
  font-size: 30px;
  color: #222222;
  z-index: 10;
  top: 0;
  font-weight: 700;
  text-align: center;
  width: 100vw;
  line-height: 30px;
}

.f2_company_title > h5 {
  width: 100vw;
  position: absolute;
  font-size: 20px;
  color: #222222;
  z-index: 10;
  text-align: center;
  top: 0;
  margin-top: 40px;
  font-weight: 700;
  line-height: 40px;
}

.f2_company {
  width: 60vw;
  background-color: #fafafa;
  display: flex;
  padding: 150px 20vw 100px;
  justify-content: space-between;
}

@media (max-width: 1300px) and (min-width: 0px) {
  .f2_company {
    width: 100vw;
    display: block;
    text-align: center;
    padding: 150px 0 100px;
  }
}

.f2_company > img {
  width: 200px;
  height: 150px;
  object-fit: cover;
}

@media (max-width: 1100px) and (min-width: 450px) {
  .f2_company > img {
    width: 300px;
    height: 230px;
    object-fit: cover;
  }
}

.f2_company > div {
  width: 40vw;
  margin-top: 0px;
}

@media (max-width: 1300px) and (min-width: 1100px) {
  .f2_company > div {
    margin: 0 auto;
  }
}

@media (max-width: 1100px) and (min-width: 0px) {
  .f2_company > div {
    width: 90vw;
    text-align: center;
    margin: 0 auto;
  }
}

.f2_company > div > h4 {
  font-size: 18px;
  font-weight: 400;
}

.f2_company > div > p {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
}


