.news {
  width: 50vw;
  margin: 60px auto;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .news {
    width: 65vw;
    margin: 60px auto;
  }
}

@media (max-width: 1000px) and (min-width: 450px) {
  .news {
    width: 75vw;
    margin: 60px auto;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .news {
    width: 90vw;
    margin: 60px auto;
  }
}

.news > h2 {
  font-size: 25px;
  color: #222222;
  margin-bottom: 45px;
}

.news > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .news > div {
    display: block;
  }
}

.news > div > h4 {
  width: 100px;
  font-size: 14px;
  color: #222222;
  font-weight: 500;
}

.news > div > h5 {
  width: calc(100% - 130px);
  font-size: 14px;
  color: #222222;
  font-weight: 500;
}

@media (max-width: 450px) and (min-width: 0px) {
  .news > div > h5 {
    width: 100%;
    margin-top: 10px;
  }
}

