.strong {
  position: relative;
  width: 100vw;
  height: 300px;
  padding: 80px 0;
  background-image: url(./../../../image/strong.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 200px;
}

.strong_posttion {
  position: absolute;
  width: 450px;
  height: 300px;
  margin: 0 calc(50vw - 225px);
  background-color: white;
  opacity: .9;
  border: 1px solid #222222;
}

.strong > div {
  position: absolute;
  width: 450px;
  margin: 0 calc(50vw - 225px);
  text-align: center;
}

@media (max-width: 1300px) and (min-width: 0px) {
  .strong > div {
    width: 90vw;
    height: 300px;
    margin: 0 5vw;
  }
}

.strong > div > h4 {
  margin: 60px 0 0;
  font-size: 20px;
  color: #222222;
}

.strong > div > h5 {
  margin: 20px 0 0;
  font-size: 15px;
  color: #222222;
  line-height: 25px;
}

.strong > div > p {
  margin: 20px 0 0;
  font-size: 13px;
  color: #222222;
  line-height: 30px;
  padding: 0 20px;
}
