.footer {
  width: 100vw;
  /* height: 80px; */
  background-color: #222222;
  color: white;
  text-align: center;
  line-height: 70px;
  font-size: 14px;
}

.footer_logo {
  color: white;
}

.footer_saikoutiku {
  text-align: right;
  font-size: 5px;
  margin-top: -50px;
  color: #777777;
}

@media (max-width: 700px) and (min-width: 0px) {
  .footer_saikoutiku {
    text-align: center;
  }
}