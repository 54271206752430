@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Serif JP', serif;;
}

.speacer {
  height: 100vh;
}

.news_calnder {
  display: flex;
  width: 70vw;
  margin: 100px auto;
}

@media (max-width: 1500px) and (min-width: 1300px) {
  .news_calnder {
    width: 80vw;
  }
}

@media (max-width: 1300px) and (min-width: 0px) {
  .news_calnder {
    width: 100vw;
    display: block;
  }
}


