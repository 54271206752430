.recruit_slider {
  position: absolute;
  width: calc(100vw - 550px);
  height: 515px;
  border-radius: 0px;
  object-fit: cover;
}

.recruit_slider_image {
  width: calc(100vw - 550px);
  height: 515px;
  margin: 0 2px;
  object-fit: cover;
}


@media (max-width: 1000px) and (min-width: 0px) {
  .recruit_slider {
    position: absolute;
    width: 100vw;
    height: 500px;
  }

  .recruit_slider_image {
    width: 100vw;
    height: 500px;
    object-fit: cover;
  }
}













