.f1_company {
  width: 70vw;
  background-color: #FFFBF2;
  display: flex;
  margin: 150px 0 0;
  padding: 100px 15vw;
  justify-content: space-between;
}

@media (max-width: 1300px) and (min-width: 700px) {
  .f1_company {
    display: block;
    text-align: center;
    margin: 150px auto 0;
    padding: 100px calc((100vw - 450px) / 2);
    width: 450px;
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .f1_company {
    width: 450px;
    display: block;
    text-align: center;
    margin: 150px auto 0;
    padding: 100px calc((100vw - 450px) / 2);
  }
}

@media (max-width: 450px) and (min-width: 0) {
  .f1_company {
    width: 100vw;
    display: block;
    text-align: center;
    padding: 100px 0;
  }
}

.f1_company > img {
  width: 450px;
  height: 400px;
  object-fit: cover;
  border-radius: 3px;
}

@media (max-width: 450px) and (min-width: 0) {
  .f1_company > img {
    width: 100vw;
    height: 400px;
    object-fit: cover;
    border-radius: 3px;
  }
}

.f1_company > div {
  width: 450px;
  height: 400px;
}

@media (max-width: 1300px) and (min-width: 700px) {
  .f1_company > div {
    width: 100%;
    margin: 45px auto 0;
    text-align: left;
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .f1_company > div {
    width: 100%;
    margin: 45px auto 0;
    text-align: left;
    height: 600px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .f1_company > div {
    width: 90%;
    margin: 45px auto 0;
    text-align: left;
    height: 600px;
  }
}

.f1_company > div > h4 {
  font-size: 15px;
  color: #222222;
  font-weight: 600;
}

.f1_company > div > div {
  display: flex;
  line-height: 35px;
  justify-content: space-between;
  border-bottom: 1px solid #D7D7D7;
  margin-top: 25px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .f1_company > div > div {
    display: block;
  }
}

.f1_company > div > div > h5 {
  font-size: 15px;
  width: 20%;
  font-weight: 500;
}

.f1_company > div > div > h6 {
  font-size: 15px;
  width: 70%;
  font-weight: 500;
}

@media (max-width: 700px) and (min-width: 0px) {
  .f1_company > div > div > h6 {
    width: 100%;
  }
}


